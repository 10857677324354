/* Reset styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  /* Style for screens with a max width of 768px (mobile devices) */
@media (max-width: 791px) {
    .opportunities-container {
      display: flex;
      flex-direction: column;
    }
    
    .opportunity {
      width: 100%;
    }
    
    .service-icon {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
  
  /* Global styles */
  body {
    font-family: Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    scroll-behavior: smooth;

  }
  
  a {
    color: #333;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  ul {
    list-style: none;
  }
  
  /* Layout styles */
  .landing-page {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }
  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logo img {
    /* height: 50px; */
    height: 30%;
    width: 30%;
  }
  
  nav ul {
    display: flex;
  }
  
  nav li {
    margin-right: 20px;
  }
  
  nav a {
    font-weight: bold;
  }
  
  .main {
    padding: 20px;
  }
  
  .hero {
    background-color: #eee;
    padding: 50px;
    text-align: center;
  }
  
  .hero h1 {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  
  .hero p {
    font-size: 1.5rem;
    margin-bottom: 30px;
  }
  
  .cta-button {
    display: inline-block;
    padding: 12px 24px;
    border-radius: 5px;
    background-color: #333;
    color: #fff;
    font-size: 1.2rem;
    text-transform: uppercase;
  }
  
  .cta-button:hover {
    background-color: #555;
  }
  
  #about {
    margin-top: 50px;
    padding: 20px;
  }
  
  #about h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  #about p {
    font-size: 1.2rem;
    margin-bottom: 30px;
  }
  
  #opportunities {
    margin-top: 50px;
  }
  
  #opportunities h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  #opportunities ul {
    display: flex;
    flex-wrap: wrap;
  }
  
  #opportunities .opportunity {
    flex-basis: calc(33.33% - 20px);
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 20px;
    background-color: #eee;
  }
  
  #opportunities li:last-child {
    margin-right: 0;
  }
  
  #opportunities h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .bullets {
    list-style-type:square;
  }
  #models {
    margin-top: 50px;
  }
  
  #models h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  #models ul {
    display: flex;
    flex-wrap: wrap;
  }
  
  #models li {
    flex-basis: calc(33.33% - 20px);
    margin-right: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  #models li:last-child {
    margin-right: 0;
  }
  
  #models img {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
  }
  
  #models h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .testimonials {
    margin-top: 50px;
  }
  
  .testimonials h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .testimonials blockquote {
    font-size: 1.2rem;
    margin-bottom: 30px;
  }
  
  #contact {
    padding: 20px;
    margin-top: 50px;
  }
  
  #contact h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    }
    
    #contact form {
    display: flex;
    flex-wrap: wrap;
    }
    
    #contact label {
    flex-basis: 100%;
    margin-bottom: 10px;
    }
    
    #contact input,
    #contact textarea {
    flex-basis: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
    }
    
    #contact input:focus,
    #contact textarea:focus {
    outline: none;
    border-color: #333;
    }
    
    #contact textarea {
    height: 150px;
    }
    
    #contact button {
    padding: 12px 24px;
    border-radius: 5px;
    background-color: #333;
    color: #fff;
    font-size: 1.2rem;
    text-transform: uppercase;
    }
    
    #contact button:hover {
    background-color: #555;
    }
    
    footer {
    background-color: #333;
    color: #fff;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    }
    
    footer ul {
    display: flex;
    }
    
    footer li {
    margin-right: 20px;
    }
    
    footer a {
    color: #fff;
    }
    
    .social-media-links a {
    color: #fff;
    margin-right: 10px;
    }
    
    .social-media-links a:last-child {
    margin-right: 0;
    }
    .scroll {
        scroll-behavior: smooth;
      }
  
      #honey {
        display: none;
      }

      